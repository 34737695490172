import React from 'react'
import { css } from '@emotion/core'
import { font, color, mediaQuery, smartPhone } from '../constants/styles'
import { ChangeColorBarThirdParty } from '../components/ChangeColorBarThirdParty'

const endBands = css`
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const centerBands = css`
  height: 200px;
  background-color: ${color.orange};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  color: ${color.white};
  font-weight: normal;
`

const title = css`
  font-size: ${font.textSize};
  font-family: ${font.TitleFontFamily};
  color: ${color.mainD};
  ${mediaQuery[smartPhone]} {
    padding-top: 100px;
    align-items: center;
    font-size: ${font.textSize};
  }
`

const subTitle = css`
  font-size: ${font.NomaltitleSize};
  padding-top: 32px;
  color: ${color.black};
  font-family: ${font.TitleFontFamily};

  ${mediaQuery[smartPhone]} {
    padding-top: 20px;
    align-items: center;
    font-size: ${font.subtitleSize};
  }
`

export const PageTitle = (props) => {
  const en = 0
  const ja = 1
  return (
    <div css={endBands}>
      <section css={centerBands}>
        <h2 css={title}>{props.title[en]}</h2>
        <p css={subTitle}>{props.title[ja]}</p>
        <ChangeColorBarThirdParty />
      </section>
    </div>
  )
}
