import React from 'react'
import { css } from '@emotion/core'
import { color, mediaQuery, smartPhone } from '../constants/styles'

const underLine = css`
  width: 100px;
  border-bottom: 2px solid ${color.gray};
  position: relative;
  margin-top:30px;
　margin-bottom:40px;
  }
  ${mediaQuery[smartPhone]} {
    margin-top:30px;
    width: 15%;
  }
`

export const ChangeColorBarThirdParty = () => {
  return <div css={underLine}></div>
}
