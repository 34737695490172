import React, { useState, useEffect } from "react";
import { Layout } from "../components/Layout";
import { Head } from "../components/Head";
import { PageTitle } from "../components/PageTitle";
import { mediaQuery, smartPhone } from "../constants/styles";
import { css } from "@emotion/core";

// 実績ができればコメントアウトを外してご使用下さい
// import { AchievementItems } from "../components/AchievementItems";

const sectionContainer = css`
  width: 60%;
  margin: 0 auto;
  padding-top: 64px;
  ${mediaQuery[smartPhone]} {
    width: 90%;
  }
`;

export default function Achievement({ location }) {
  const title = "ACHIEVEMENT";
  const subTitle = "開業実績";
  const [pathname, setPathname] = useState("");
  useEffect(() => {
    setPathname(location.pathname);
  }, []);
  return (
    <Layout location={pathname}>
      <Head props={subTitle} />
      <PageTitle title={[title, subTitle]} />
      <p css={sectionContainer}>現在準備中</p>
      {/* 実績ができればコメントアウトを外してご使用下さい */}
      {/* <AchievementItems /> */}
    </Layout>
  );
}
